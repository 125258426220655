import "./App.css";
import { BrowserRouter as Router, Routes, Link, Route } from "react-router-dom";
import { Onama } from "./Onama";
import { Emisije } from "./Emisije";
import { NajnovijeVijesti } from "./NajnovijeVijesti";
import { Kontakt } from "./Kontakt";
import { Tim } from "./Tim";
import { Galerija } from "./Galerija";

function App() {
  return (
    <>
      <Router>
        <header className="main-header clearfix">
          <div className="top-bar">
            <div className="container">
              <div className="row clearfix">
                <div className="col-md-6 col-sm-12">
                  <div className="contact-info">
                    <div className="contact-details">
                      <div className="social-icons">
                        <a href="https://www.facebook.com/profile.php?id=100094573102351&mibextid=ViGcVu">
                          <i className="fab fa-facebook"></i>
                        </a>
                        <a href="https://x.com/djedovina?t=WsOH3Nu6OQShkelHzoBoYA&s=09">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a href="https://youtube.com/@djedovinacg?si=f_n-yyTPMEngvrGs">
                          <i className="fab fa-youtube"></i>
                        </a>
                        <a href="https://instagram.com/djedovinacg?igshid=OTBlMGdoamE5YWZx">
                          <i className="fab fa-instagram"></i>
                        </a>
                        <a href="https://www.tiktok.com/@djedovina?_t=8hCK6LV8heP&_r=1">
                          <i className="fab fa-tiktok"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12 text-right hidden-xs top-donation-btn-container">
                  <a className="top-phone-no" href="tel:+38268898512 ">
                    <i className="fa fa-phone-square"></i>
                    +38268898512
                  </a>

                  <a className="top-phone-no" href="tel:+38267099997 ">
                    <i className="fa fa-phone-square"></i>
                    +38267099997
                  </a>

                  <span className="top-email-info">
                    <i className="fa fa-paper-plane"></i>
                    <a href="mailto:djedovinacg@gmail.com">
                      djedovinacg@gmail.com
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <section className="header-wrapper navgiation-wrapper">
            <nav className="navbar navbar-default">
              <div className="container">
                <div className="navbar-header">
                  <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target=".navbar-collapse"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <a className="logo" href="/">
                    <img
                      alt=""
                      style={{ width: "170px" }}
                      src="images/djedovina-logo-transparent.png"
                    ></img>
                  </a>
                </div>

                <div className="collapse navbar-collapse">
                  <div>
                    <ul className="nav navbar-nav navbar-right">
                      <li className="drop">
                        <Link to="/">O Nama</Link>
                      </li>
                      <li className="drop">
                        <Link to="/emisije">Emisije</Link>
                      </li>
                      <li className="drop">
                        <Link to="/najnovijevijesti">Najnovije Vijesti</Link>
                      </li>
                      <li className="drop">
                        <Link to="/galerija">Galerija</Link>
                      </li>
                      <li className="drop">
                        <Link to="/kontakt">Kontakt</Link>
                      </li>
                      <li className="drop">
                        <Link to="/tim">Tim</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </section>
        </header>

        <Routes>
          <Route path="/" element={<Onama />} />
          <Route path="/emisije" element={<Emisije />} />
          <Route path="/najnovijevijesti" element={<NajnovijeVijesti />} />
          <Route path="/galerija" element={<Galerija />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/tim" element={<Tim />} />
        </Routes>
        <footer>
          <section className="footer-section">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <span>
                    Copyright © 2023. All right reserved&nbsp;
                    <a className="color-primary" href="https://djedovinacg.me/">
                      Creativhub
                    </a>
                  </span>
                </div>

                <div className="col-md-6 col-sm-12">
                  <div className="footer-nav">
                    <nav>
                      <ul>
                        <li>
                          <Link to="/">O Nama</Link>
                        </li>
                        <li>
                          <Link to="/emisije">Emisije</Link>
                        </li>
                        <li>
                          <Link to="/najnovijevijesti">Najnovije Vijesti</Link>
                        </li>
                        <li>
                          <Link to="/galerija">Galerija</Link>
                        </li>
                        <li>
                          <Link to="/kontakt">Kontakt</Link>
                        </li>
                        <li>
                          <Link to="/tim">Tim</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </footer>
      </Router>
      <script src="js/jquery.min.js"></script>
      <script src="js/bootstrap.min.js"></script>
      <script src="js/wow.min.js"></script>
      <script src="js/jquery.backTop.min.js "></script>
      <script src="js/waypoints.min.js"></script>
      <script src="js/waypoints-sticky.min.js"></script>
      <script src="js/owl.carousel.min.js"></script>
      <script src="js/jquery.stellar.min.js"></script>
      <script src="js/jquery.counterup.min.js"></script>
      <script src="js/venobox.min.js"></script>
      <script src="js/custom-scripts.js"></script>
      <script src="https://maps.google.com/maps/api/js?sensor=true"></script>
      <script src="js/jquery.gmap.min.js"></script>
    </>
  );
}

export default App;
